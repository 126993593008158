import React, { useReducer } from "react";
import Spinner from "react-bootstrap/Spinner";
/*MPORT-406 Global Spinner to show when a service data is fetched */
export const LoaderDispatcher = {
  dispatch: () => {
    // empty method
  },
};
function GlobalLoaderReducer(state, action) {
  if (action.type === "ShowAndHideLoader") {
    return {
      ...state,
      loading: action.payload.loading,
    };
  } else {
    return state;
  }
}
const initialState = {
  loading: false,
};
const GlobalLoader = (props) => {
  const [state, reducer] = useReducer(GlobalLoaderReducer, initialState);
  const { loading } = state;
  LoaderDispatcher.dispatch = (params) => reducer(params);
  const { size, animation, variant, inline } = props;
  return (
    <>
      {loading && (
        <>
          {/** for buttons spinner should be displayed inline , other places it should be full page */}
          {inline === "false" ? (
            <div className="d-flex justify-content-center content-center ">
              <Spinner
                as="span"
                animation={animation}
                variant={variant}
                size={size}
              />
            </div>
          ) : (
            <Spinner
              as="span"
              animation={animation}
              variant={variant}
              size={size}
            />
          )}
        </>
      ) }
    </>
  );
};
GlobalLoader.defaultProps = {
  size: 'xl',
  animation: 'border',
  variant: 'primary',
}
export default GlobalLoader;
