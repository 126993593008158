const config = {
  api: {
    webKeyAPI: {
      name: "/authentication/webKeyValidation",
      type: "POST",
    },
    fileUpload: {
      name: "/fileUpload",
      type: "POST",
    },
    refreshtoken: {
      name: "/authentication/refreshtoken",
      type: "GET",
    },
    fileDelete: {
      name: "/fileUpload/delete",
      type: "POST",
    },
    eventTrackingAPI: {
      name: "/eventTracking/logEvent",
      type: "POST",
    },
    getConfigAPI: {
      name: "/getConfig",
      type: "GET",
    },
    balanceBillUpload: {
      name: "/balanceBill/upload",
      type: "POST",
    },
    maintenanceAPI: {
      name: "/maintenance",
      type: "GET",
    },
  },
  validationMsg: {
    invalidEntry: "Invalid entry",
    displayContact: "Invalid entry - contact the sender listed in your email",
    futureDate: "Cannot be Future Date",
    wrongDateFormat: "Must be Format MM/DD/YYYY",
    fieldsRequired: "Fields Are Required",
  },
  content: {
    headerText: "Patient Advocacy Portal",
    whoIsDataiSightQ: "Who is Data iSight?",
    whoIsDataiSightA: `We are a service your insurance carrier has engaged to assist
        with pricing out-of-network claims. When you are seen by an
        out-of-network healthcare provider, we provide fair and
        transparent pricing for the bills sent to your healthplan. When
        you (or a member of your healthplan) receive a balance bill, we
        help to negotiate on your behalf with the healthcare provider.`,
  },
};
export default config;
