import { useState, useEffect } from 'react';
const Authentication = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  useEffect(() => {
    function handleStatusChange(status) {
      setIsLoggedIn(status);
    }
    /* you can even make api call here based the parameter you receive*/
    const currentStatus = sessionStorage.getItem('loggedIn') !== undefined ? sessionStorage.getItem('loggedIn') : null;
    handleStatusChange(currentStatus)
  });
  return isLoggedIn;
}
export default Authentication;