import { useState, useEffect } from "react";
const CheckNewTab = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  useEffect(() => {
    //Called On First Load
    if (!sessionStorage.length) {
      localStorage.setItem("NRTETG", Date.now());
    }
    window.onstorage = (event) => {
      //Sets Post TinValidation
      if (
        event.key === "NRTETG" &&
        sessionStorage.getItem("loggedIn") === "true"
      ) {
        localStorage.setItem("AOTPG", JSON.stringify("any-token-you-want"));
        localStorage.removeItem("AOTPG");
      }
      //Calls on new tab load
      if (event.key === "AOTPG") {
        sessionStorage.setItem("SESloggedIn", "true");
        setIsLoggedIn(sessionStorage.getItem("SESloggedIn"));
      }
    };
  }, [sessionStorage.getItem("loggedIn")]);
  return isLoggedIn;
};
export default CheckNewTab;
