import React, {lazy} from "react";
const ModalBox = lazy(() => import('./ModalBox'))
export const setError = {
  dispatch: () => {
    // empty method
   }
}
const initialState = {
  show: false,
  error: ""
};
const errorReducer = (state, action) => {
  switch (action.type) {
    case "SHOW":
      return {
        ...state,
        show: true,
        error: action.error
      };
    case "HIDE":
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};
const ErrorModal = (props) => {
  const [state, dispatchError] = React.useReducer(errorReducer, initialState);
  const { show, error } = state;
  const handleClose = () => {
    dispatchError({ type: "HIDE" })
  }
  setError.dispatch = (params) => dispatchError(params);
  return (
    <ModalBox handleClose={handleClose} show={show} type="alert" body={error} size="md" title="Error"/>
  )
}
export default ErrorModal;
