import axios from "axios";
import { setError } from "../components/common/ErrorModal";
import { LoaderDispatcher } from "../components/common/GlobalLoader";
import { setContext } from "../context/RBPPortalContext";

const axiosInstance = axios.create({
  baseURL: "",
  timeout: 30000,
  headers: { common: { "X-CSRF-Token": null } },
  // headers: {'X-Custom-Header': 'foobar'}
});
const RequestConfig = (request) => {
  // Do something before request is sent
  //MPORT-406 Show the spinner while making a call

  //MPORT-54 Request times out for pdf generation
  if (request.url === "/claimAgreements/loasGeneration") {
    request.timeout = 60000;
  }
  if (request.url === "/fileUpload") {
    request.timeout = 240000;
  }
  if (request.url === "/fileUpload/delete") {
    request.timeout = 240000;
  }
  LoaderDispatcher.dispatch({
    type: "ShowAndHideLoader",
    payload: { loading: true },
  });
  return request;
};
const RequestErr = (error) => {
  // Do something with request error
  return error;
};
// Add a request interceptor
axiosInstance.interceptors.request.use(RequestConfig, RequestErr);
const responseInterceptor = (response) => {
  if (response.config.url === "/csrf-token") {
    axiosInstance.defaults.headers.common["X-CSRF-Token"] =
      response.data && response.data.csrfToken ? response.data.csrfToken : "";
  }
  //MPORT-406 = Hide the spinner
  LoaderDispatcher.dispatch({
    type: "ShowAndHideLoader",
    payload: { loading: false },
  });
  return response;
};
const errorInterceptor = (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response) {
    if (error.response.status === 401) {
      sessionStorage.setItem("loggedIn", false);
      setContext.dispatch({
        type: "ReturnToWebKey",
      });
    } else if (error.response.status !== 200) {
      setError.dispatch({
        type: "SHOW",
        error: "Internal Error Occurred, please try again later.",
      });
    }
  }
  LoaderDispatcher.dispatch({
    type: "ShowAndHideLoader",
    payload: { loading: false },
  });
  return Promise.reject(error);
};
// Add a response interceptor
axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
// API Call
const requestAPI = (method, url, params = {}, data = {}) => {
  return axiosInstance({
    method: method,
    url: url,
    params: params,
    data: data,
  });
};
export default {
  axiosInstance,
  requestAPI,
};
