import React from "react";
import "./asserts/scss/App.scss";
import { BrowserRouter } from "react-router-dom";
import { RBPPortalContextProvider } from "./context/RBPPortalContext";
import Main from "./layout/Main";
import withClearCache from './ClearCache';

function App() {
  return (
    <BrowserRouter>
      <RBPPortalContextProvider>
          <Main />
      </RBPPortalContextProvider>
    </BrowserRouter>
  );
}

export default withClearCache(App);
