import React, { createContext } from "react";
export const RBPPortalContext = createContext();

export const setContext = {
  dispatch: () => {
    // empty method
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "Authenticated":
      return {
        ...state,
        isAuthenticated: true,
      };
    case "ReturnToWebKey":
      sessionStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
export const RBPPortalContextProvider = (props) => {
  const initialState = {
    isAuthenticated: sessionStorage.getItem("loggedIn"),
    loading: false,
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  setContext.dispatch = (params) => dispatch(params);
  return (
    <RBPPortalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </RBPPortalContext.Provider>
  );
};
