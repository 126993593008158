import React, { useState, useEffect } from "react";
import packageJson from "../package.json";
import moment from "moment";
 
/*
  This function compares the two date/time values, 
  it returns true if the latest build date is greater than the cached build date
  else false.
  We are using the moment library for date/time comparison.
*/
 const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);
  return momLatestDateTime.isAfter(momCurrentDateTime) ? true : false;
};
 
function ClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
 
    /*
      We are making an api call to meta.json file to access it's content.
      Browsers don't cache the api calls, so even if our files are cached we will always get the latest response from it.
    */
    useEffect(() => {
      fetch("/meta.json")
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;
 
          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    /*
      If the lastest build date is greater than the cached build date 
      we will delete the service worker cache, browser cache and do a hard reload.
    */
    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
    };
 
    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    );
  }
 
  return ClearCacheComponent;
}
 
export default ClearCache;